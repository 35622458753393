import { ChangeDetectorRef, Component } from '@angular/core';

import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { finalize, Subject, takeUntil } from 'rxjs';
import { ToastService } from '../../../../core/services/toast/toast.service';
import { Location } from '@angular/common';
import { UserI } from '../../../../core/shared/interfaces/entities/user.interface';
import { CustomerAccountBaseSideMenuComponent } from './customer-account-base-side-menu/customer-account-base-side-menu.component';
import { BusinessAccountsService } from '../../../business-accounts/business-accounts.service';
import { DelegatedUIErrorI } from '../../../../core/shared/interfaces/general/delegated-ui-error.interface';
import { BusinessAccountsApiService } from '../../../business-accounts/business-accounts-api.service';
@Component({
  selector: 'app-customer-account-details-base',
  standalone: true,
  imports: [CustomerAccountBaseSideMenuComponent, RouterOutlet],
  templateUrl: './customer-account-details-base.component.html',
  styleUrl: './customer-account-details-base.component.scss',
})
export class CustomerAccountDetailsBaseComponent {
  constructor(
    private readonly route: ActivatedRoute,
    private readonly location: Location,
    private readonly translateService: TranslateService,
    private readonly toastService: ToastService,
    private readonly cd: ChangeDetectorRef,
    private readonly businessAccountsApiService: BusinessAccountsApiService,
    private readonly businessAccountsService: BusinessAccountsService
  ) {}
  private readonly destroy$ = new Subject<void>();
  selectedCustomerAccount: UserI | null = null;

  ngOnInit() {
    this.getCustomerAccountIDFromRouteParams();
  }

  getCustomerAccountIDFromRouteParams() {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((paramMap) => {
      const customerAccountID = paramMap.get('customerAccountID');
      if (!customerAccountID) {
        this.toastService.showError(
          this.translateService.instant('errors.contactTechnicalSupport')
        );
        this.location.back();
      } else {
        this.fetchBusinessAccount(customerAccountID);
      }
    });
  }
  fetchBusinessAccount(customerAccountID: string) {
    this.businessAccountsApiService
      .findOne(customerAccountID)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.cd.detectChanges())
      )
      .subscribe({
        next: (findOneResponse) => {
          const { data } = findOneResponse;
          this.selectedCustomerAccount = data;
          this.businessAccountsService.selectedBusinessAccount.set(
            this.selectedCustomerAccount
          );
        },
        error: (err: DelegatedUIErrorI) => {
          this.toastService.showError(err.description, err.title);
        },
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
