import { NavItemI } from '../../../../core/shared/interfaces/general/nav-item.interface';

export const SIDE_MENU_ITEMS: NavItemI[] = [
  {
    translationPath: 'sideMenu.dashboard',
    activeIcon: '/svgs/side-menu/active-dashboard.svg',
    nonActiveIcon: '/svgs/side-menu/non-active-dashboard.svg',
    route: 'dashboard',
    id: 6,
    alt: 'Dashboard',
  },
  {
    translationPath: 'sideMenu.topFive',
    activeIcon: '/svgs/side-menu/active-top-five.svg',
    nonActiveIcon: '/svgs/side-menu/non-active-top-five.svg',
    route: 'top-five',
    id: 15,
    alt: 'Top Five',
  },
  {
    translationPath: 'sideMenu.businessAccount',
    activeIcon: '/svgs/side-menu/active-business-account.svg',
    nonActiveIcon: '/svgs/side-menu/non-active-business-account.svg',
    route: 'business-accounts',
    id: 1,
    alt: 'Business-account',
  },

  {
    translationPath: 'sideMenu.financeManagement',
    activeIcon: '/svgs/side-menu/active-finance-management.svg',
    nonActiveIcon: '/svgs/side-menu/non-active-finance-management.svg',
    route: 'finance-management',
    id: 5,
    alt: 'Finance management',
  },

  {
    translationPath: 'sideMenu.support',
    activeIcon: '/svgs/side-menu/active-support.svg',
    nonActiveIcon: '/svgs/side-menu/non-active-support.svg',
    route: 'support',
    id: 8,
    alt: 'Support',
  },
  {
    translationPath: 'sideMenu.industries',
    activeIcon: '/svgs/side-menu/active-services.svg',
    nonActiveIcon: '/svgs/side-menu/non-active-services.svg',
    route: 'industries',
    id: 16,
    alt: 'Industries',
  },
  {
    translationPath: 'sideMenu.customerManagement',
    activeIcon: '/svgs/side-menu/active-customer-management.svg',
    nonActiveIcon: '/svgs/side-menu/non-active-customer-management.svg',
    route: 'customer-management',
    id: 17,
    alt: 'Customer Management',
  },
  {
    translationPath: 'sideMenu.ads',
    activeIcon: '/svgs/side-menu/active-ads.svg',
    nonActiveIcon: '/svgs/side-menu/non-active-ads.svg',
    route: 'ads',
    id: 18,
    alt: 'Ads',
  },
  {
    translationPath: 'sideMenu.mails',
    activeIcon: '/svgs/side-menu/active-mails.svg',
    nonActiveIcon: '/svgs/side-menu/non-active-mails.svg',
    route: 'mails',
    id: 19,
    alt: 'Mails',
  },
  {
    translationPath: 'sideMenu.notifications',
    activeIcon: '/svgs/side-menu/active-notifications.svg',
    nonActiveIcon: '/svgs/side-menu/non-active-notifications.svg',
    route: 'notifications',
    id: 20,
    alt: 'Notifications',
  },
  {
    translationPath: 'B A A',
    activeIcon: '/svgs/side-menu/active-baa.svg',
    nonActiveIcon: '/svgs/side-menu/non-active-baa.svg',
    route: 'business-accounts-applications',
    id: 21,
    alt: 'Business Accounts Applications',
  },
  {
    translationPath: 'C C A',
    activeIcon: '/svgs/side-menu/active-cca.svg',
    nonActiveIcon: '/svgs/side-menu/non-active-cca.svg',
    route: 'content-creator-applications',
    id: 22,
    alt: 'Content Creator Applications',
  },
];
