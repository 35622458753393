import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { BusinessAccountBaseSideMenuComponent } from './business-account-base-side-menu/business-account-base-side-menu.component';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { finalize, Subject, takeUntil } from 'rxjs';
import { ToastService } from '../../../../core/services/toast/toast.service';
import { Location } from '@angular/common';
import { UserI } from '../../../../core/shared/interfaces/entities/user.interface';
import { BusinessAccountsApiService } from '../../business-accounts-api.service';
import { BusinessAccountsService } from '../../business-accounts.service';
import { DelegatedUIErrorI } from '../../../../core/shared/interfaces/general/delegated-ui-error.interface';

@Component({
  selector: 'app-business-account-details-base',
  standalone: true,
  imports: [BusinessAccountBaseSideMenuComponent, RouterOutlet],
  templateUrl: './business-account-details-base.component.html',
  styleUrl: './business-account-details-base.component.scss',
})
export class BusinessAccountDetailsBaseComponent implements OnInit, OnDestroy {
  constructor(
    private readonly route: ActivatedRoute,
    private readonly location: Location,
    private readonly translateService: TranslateService,
    private readonly toastService: ToastService,
    private readonly cd: ChangeDetectorRef,
    private readonly businessAccountsApiService: BusinessAccountsApiService,
    private readonly businessAccountsService: BusinessAccountsService
  ) {}

  private readonly destroy$ = new Subject<void>();
  selectedBusinessAccount: UserI | null = null;

  ngOnInit() {
    this.getBusinessAccountIDFromRouteParams();
  }

  getBusinessAccountIDFromRouteParams() {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((paramMap) => {
      const businessAccountID = paramMap.get('businessAccountID');
      if (!businessAccountID) {
        this.toastService.showError(
          this.translateService.instant('errors.contactTechnicalSupport')
        );
        this.location.back();
      } else {
        this.fetchBusinessAccount(businessAccountID);
      }
    });
  }

  fetchBusinessAccount(businessAccountID: string) {
    this.businessAccountsApiService
      .findOne(businessAccountID)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.cd.detectChanges())
      )
      .subscribe({
        next: (findOneResponse) => {
          const { data } = findOneResponse;
          this.selectedBusinessAccount = data;
          this.businessAccountsService.selectedBusinessAccount.set(
            this.selectedBusinessAccount
          );
        },
        error: (err: DelegatedUIErrorI) => {
          this.toastService.showError(err.description, err.title);
        },
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
