import { NavItemI } from '../interfaces/nav-item.interface';

export const SIDE_MENU_ITEMS: NavItemI[] = [
  {
    translationPath: 'Overview',
    activeIcon: 'svgs/side-menu/active-overview.svg',
    nonActiveIcon: 'svgs/side-menu/non-active-overview.svg',
    route: (userID: string) => `customer-management/${userID}/overview`,
    id: 1,
    alt: 'Overview',
  },
  {
    translationPath: 'Orders',
    activeIcon: 'svgs/side-menu/active-store.svg',
    nonActiveIcon: 'svgs/side-menu/non-active-store.svg',
    route: (userID: string) => `customer-management/${userID}/orders`,
    id: 2,
    alt: 'Orders',
  },
  {
    translationPath: 'Appointments',
    activeIcon: 'svgs/side-menu/active-services.svg',
    nonActiveIcon: 'svgs/side-menu/non-active-services.svg',
    route: (userID: string) => `customer-management/${userID}/appointments`,
    id: 3,
    alt: 'Appointments',
  },
  {
    translationPath: 'Posts',
    activeIcon: 'svgs/side-menu/active-posts.svg',
    nonActiveIcon: 'svgs/side-menu/non-active-posts.svg',
    route: (userID: string) => `customer-management/${userID}/posts`,
    id: 4,
    alt: 'Posts',
  },
  {
    translationPath: 'Reels',
    activeIcon: 'svgs/side-menu/active-posts.svg',
    nonActiveIcon: 'svgs/side-menu/non-active-posts.svg',
    route: (userID: string) => `customer-management/${userID}/reels`,
    id: 5,
    alt: 'Reels',
  },
];
