<article class="top-five-base-container">
  <section class="switches-container">
    <button
      class="switch active"
      routerLink="./stores"
      routerLinkActive="active"
      ariaCurrentWhenActive="page"
    >
      Online Stores
    </button>
    <button
      class="switch"
      routerLink="./providers"
      routerLinkActive="active"
      ariaCurrentWhenActive="page"
    >
      Services
    </button>
  </section>
  <hr />
  <section class="table"><router-outlet></router-outlet></section>
</article>
