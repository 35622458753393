import { NavItemI } from '../interfaces/nav-item.interface';

export const SIDE_MENU_ITEMS: NavItemI[] = [
  {
    translationPath: 'Overview',
    activeIcon: 'svgs/side-menu/active-overview.svg',
    nonActiveIcon: 'svgs/side-menu/non-active-overview.svg',
    route: (userID: string) => `business-accounts/${userID}/overview`,
    id: 1,
    alt: 'Overview',
  },
  {
    translationPath: 'Online Store',
    activeIcon: 'svgs/side-menu/active-store.svg',
    nonActiveIcon: 'svgs/side-menu/non-active-store.svg',
    route: (userID: string) => `business-accounts/${userID}/store`,
    id: 2,
    alt: 'Online Store',
  },
  {
    translationPath: 'Services',
    activeIcon: 'svgs/side-menu/active-services.svg',
    nonActiveIcon: 'svgs/side-menu/non-active-services.svg',
    route: (userID: string) => `business-accounts/${userID}/services`,
    id: 3,
    alt: 'Services',
  },
  {
    translationPath: 'Posts',
    activeIcon: 'svgs/side-menu/active-posts.svg',
    nonActiveIcon: 'svgs/side-menu/non-active-posts.svg',
    route: (userID: string) => `business-accounts/${userID}/posts`,
    id: 4,
    alt: 'Posts',
  },
  {
    translationPath: 'Reels',
    activeIcon: 'svgs/side-menu/active-posts.svg',
    nonActiveIcon: 'svgs/side-menu/non-active-posts.svg',
    route: (userID: string) => `business-accounts/${userID}/reels`,
    id: 5,
    alt: 'Reels',
  },
  {
    translationPath: 'Commission',
    activeIcon: 'svgs/side-menu/active-commission.svg',
    nonActiveIcon: 'svgs/side-menu/non-active-commission.svg',
    route: (userID: string) => `business-accounts/${userID}/commission`,
    id: 6,
    alt: 'Commission',
  },
];
