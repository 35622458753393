import { Component } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-top-five-base',
  standalone: true,
  imports: [RouterOutlet, RouterLink, RouterLinkActive],
  templateUrl: './top-five-base.component.html',
  styleUrl: './top-five-base.component.scss',
})
export class TopFiveBaseComponent {}
