<article class="stores">
  <section class="stores-container">
    <div class="switches">
      <br />
      <br /><br />
      <div class="top-bar">
        <a routerLink="./orders">Orders</a>
        <a routerLink="./categories">Categories</a>
        <a routerLink="./products"> Products </a>
      </div>
    </div>
    <hr class="line" />
  </section>
  <router-outlet></router-outlet>
</article>
