<article class="services">
  <section class="services-container">
    <div class="switches">
      <br />
      <br /><br />
      <div class="top-bar">
        <a routerLink="./appointments">Appointments</a>
        <a routerLink="./categories">Categories</a>
        <a routerLink="./provider-services"> Services </a>
      </div>
    </div>
    <hr class="line" />
  </section>
  <router-outlet></router-outlet>
</article>
